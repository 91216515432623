﻿/// <reference path="variables.less" />

// Variables
@tertiary-color: #fff;
@menu-border: 1px solid #eee;
@menu-border-color: #eee;

@DX1gray: #434345;
@text-color: @DX1gray;

@brand-primary: #428bca;
@brand-primary-darker: #347cb9;

@screen-xs: 480px;
@screen-xs-plus: 640px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-md-plus: 1024px;
@screen-lg: 1200px;
@screen-xs-max: (@screen-sm - 1);
@screen-sm-max: (@screen-md - 1);
//-------------------------------------------------//
//--------  Mega Menu Base Skin Styles #1  --------//
//-------------------------------------------------//

.menu-large {
    position: static !important;
}


.navbar-collapse .nav.navbar-nav li.dropdown {
    //---------------  General Components Single & Multiple OEM  ---------------
    .megamenu {
        top: auto;
        background-color: @tertiary-color;
        box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.5);
        border-top: 2px solid @text-color;
        padding: 15px;
        margin-left: 0;
        width: 100%;

        .megamenu-content {
            max-width: 1920px;

            > div {
                display: flex;
                flex-wrap: wrap;

                @media (min-width:@screen-lg) {
                    flex-direction: row;
                }
            }
        }

        @media (max-width: 767px) {
            padding: 15px 0;

            .megamenu-content {
                padding-left: 0;
                padding-right: 0;
            }
        }
        /*  Title  */
        .megamenu-title {
            color: @text-color;
            font-weight: bold;
            margin-bottom: 20px;
            padding: 0px;
            text-align: center;
            font-weight: bold;

            @media (min-width:768px) {
                text-align: left;
            }

            @media (min-width:@screen-lg) and (max-width:1700px) {
                padding: 0px 15px;
                margin-bottom: 10px;
            }
        }
        /*  Items  */
        .megamenu-item {
            overflow: hidden;
            padding-bottom: 0;
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            @media (min-width:640px) {
                border: none;
            }

            @media (min-width:@screen-sm) {
                padding: 5px;
            }

            @media (min-width:@screen-lg) {
                flex-grow: 1;
                flex-shrink: 1;
                margin-bottom: 0px;
                max-width: 20%;
            }
            /*  Thumbnail  */
            .mega-thumbnail {
                overflow: hidden;
                width: 100%;
                border: none;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .mega-thumbnail-link {
                    display: flex;
                    padding: 0;
                    margin: auto;
                    cursor: pointer;
                    background: none;

                    @media (min-width:@screen-sm) {
                        padding: 10px;
                    }

                    img {
                        width: auto;
                        height: auto;
                        border: 0;
                        margin: auto;
                    }
                }
            }
            /*  Text  */
            .mega-text-wrap {
                position: absolute;
                bottom: 0;
                left: 0;
                width: auto;
                background-color: rgba(255, 255, 255, 0.4);

                @media (min-width:@screen-sm) {
                    position: relative;
                    margin-top: 15px;
                    padding: 0 10px;
                    bottom: 10px;
                    width: 100%;
                    height: 100px;
                }

                .logo-text {
                    font-weight: bold;
                    text-transform: none;
                    margin-bottom: 0;

                    @media (min-width: @screen-sm) {
                        margin-bottom: 10px;
                    }

                    a {
                        color: #000;
                        font-size: 1.429rem;
                        display: inline-block;
                        padding: 5px 10px;
                        background: none;

                        @media (min-width:640px) {
                            font-size: 1.286rem;
                        }

                        @media (min-width:768px) {
                            color: @text-color;
                            display: block;
                            border-bottom: 1px solid #ccc;
                            padding: 5px 0 10px 0;
                        }

                        &:hover {
                            background: none;
                            text-decoration: none;
                        }
                    }
                }

                .mega-link {
                    position: relative;
                    padding: 15px 15px 15px 24px;
                    bottom: 8px;
                    color: #000;
                    background: none;

                    @media (min-width:768px) {
                        color: @brand-primary;
                        display: block;
                        height: auto;
                        line-height: normal;
                        border: none;
                        bottom: 0;
                        padding: 0 5px 8px 30px;
                    }

                    .link-icon {
                        width: 20px;
                        height: 20px;
                        background-color: @brand-primary;
                        position: absolute;
                        top: 0;
                        left: 3px;
                        color: #fff;
                        text-align: center;
                        font-weight: bold;
                        border: 2px solid @brand-primary;

                        @media (max-width:767px) {
                            height: 100%;
                            color: #000;
                            background: none;
                            top: 3px;
                            left: 5px;
                            border: none;
                            padding-top: 15px;
                        }
                    }

                    &:hover {
                        background: none;
                        color: @brand-primary-darker;
                        cursor: pointer;

                        .link-icon {
                            background-color: #fff;
                            color: @brand-primary;
                        }
                    }
                }
            }

            &:hover {
                .logo-text {
                    a {
                        color: @brand-primary-darker;
                    }
                }
            }
        }

        &.open {
            /**  Overwrite bookstrap inherited nav styles  **/
            a {
                background: none !important;
            }

            .dropdown-menu {

                @media (min-width:@screen-sm) {
                    position: absolute !important;
                }
            }
        }
    }
}
